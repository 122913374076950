import React, { Component } from 'react'
import axios from "axios"
import store from "store"
import Layout from "../../../Components/reusable/Layout"
import Banner from "../../../Components/reusable/Banner"
import img from "../../../Images/products/agri.jpg"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CategoryDetail from '../../../Components/category/CategoryDetail'


export class Agriculture extends Component {
    state={
        categories:[],
        value:1,
        products:[]
    }



    FetchData = ()=>{
    axios.post("https://www.iamrachit.in/klsons/appapi/categories",
    JSON.stringify({
    "service_request": {
        "params": {
            "cat_type": "sprockets"
        },
        "request_info": {
            "source_type": "android"
        }
    },
    "version": "1.0"

     }),{
 headers: {
     'Content-Type': 'application/json'
   }
})
.then(res=>{
    console.log("categories=>",res);
    this.setState({
        ...this.state,
        categories:res.data.categories[3]
    })
    })
}
    componentDidMount(){
        this.FetchData()
    }

    inc = ()=>{
        this.setState({
            ...this.state,
            value:this.state.value+1
        })
    }
    dec = ()=>{
        if (this.state.value>1) {
            this.setState({
            ...this.state,
            value:this.state.value-1
        })
        }
    }

    handleChange =(e)=>{
       
        this.setState({
            ...this.state,
            value:Number(e.target.value)
        })
           
        
    }


    
   
    AddToEnq = (value=1,message="") =>{

        toast.error(`${this.state.categories.category_title} added for enquiry`,{autoClose: 1000,})
        let newProduct = []

        const myProduct = {
            name: this.state.categories.category_title,
            quantity:value,
            message
        }
        
        if (store.get("products")) {
            newProduct= store.get("products")
            newProduct.push(myProduct)
            store.set("products",newProduct)
            this.setState({
            ...this.state,
            products:newProduct
        })
           
        }else{
            newProduct.push(myProduct)
            store.set("products",newProduct)
             this.setState({
            ...this.state,
            products:newProduct
        })
        }

    }





    render() {
            const {category_title,short_desc,full_desc,category_photo} = this.state.categories
        return (
             <CategoryDetail title={category_title} img={category_photo} short_desc={short_desc} full_desc={full_desc} AddToEnq={this.AddToEnq} inc={this.inc} dec={this.dec} />
      
        )
    }
}

export default Agriculture
